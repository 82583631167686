<template lang="pug">
.main-container
  slot
</template>

<script type="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Container',
});
</script>

<style lang="stylus">
.main-container
  padding 0 6.5rem 4rem
  width 100%
  display flex
  flex-direction column
  flex-grow 1
</style>
