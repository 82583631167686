<template lang="pug">
.container-header
  slot
</template>

<script type="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContainerHeader',
});
</script>

<style lang="stylus">
.container-header
  padding 4rem 2rem 4rem 5rem
  width 100%
  display flex
  flex-wrap wrap
  &--regestry
    align-items center
  &--objects
    padding: 4rem 2rem 4rem 2rem
</style>
